import {FC, useCallback} from 'react';
import {
  AgentInfo,
  ButtonWrapper,
  Dot,
  ImageWrapper,
  Img,
  InfoWrapper,
  MainInfo,
  Status,
  StatusWrapper,
  StreetText,
  Wrapper,
} from './GridRequestCard.styles';
import free from '@/assets/images/Dashboard/free.png';
import {useData} from './useData';
import {RequestItem} from '@/features/listings/types';
import {Button} from '@/components/Core/Button';
import Text from '@/components/ui/Text';

type TProps = {
  item: RequestItem;
  isLoading?: boolean;
};
export const GridRequestCard: FC<TProps> = ({item, isLoading}) => {
  const {onRespond} = useData(item);

  const status = useCallback(() => {
    switch (item.prop.std_status_display) {
      case 'Pending':
        return 4;
      case 'Withdrawn':
        return 3;
      case 'Closed':
        return 2;
      default:
        return 1;
    }
  }, [item.prop.std_status_display]);

  return (
    <Wrapper type={true}>
      <ImageWrapper>
        <Img
          img={free.src}
          isLoading={!!isLoading}
          src={item.prop.main_pic || item.prop.main_img}
        />
        {!isLoading && (
          <StatusWrapper>
            <div style={{pointerEvents: 'none'}}>
              <Status type={status()} complete={true}>
                <Dot type={status()} complete={true} />
                <div>
                  {' '}
                  {item.prop.is_archived
                    ? 'Archived'
                    : item.prop.std_status_display}
                </div>
              </Status>
            </div>
          </StatusWrapper>
        )}
      </ImageWrapper>
      <InfoWrapper>
        <MainInfo>
          <StreetText isLoading={!!isLoading}>
            {' '}
            {isLoading ? 'skeleton' : item.prop.full_address}
          </StreetText>
        </MainInfo>
        <AgentInfo>
          <span>{item.prop.agent_name}</span> has invited you to{' '}
          <Text weight="bold">{item.role == 4 ? 'follow' : 'manage'}</Text> this
          listing.
        </AgentInfo>
        <ButtonWrapper>
          <Button
            width="100%"
            margins={{right: '4px'}}
            color="transparent"
            onClick={() => onRespond(false)}>
            Decline
          </Button>
          <Button
            color="gold"
            width="100%"
            margins={{left: '4px'}}
            onClick={() => onRespond(true)}>
            Accept
          </Button>
        </ButtonWrapper>
      </InfoWrapper>
    </Wrapper>
  );
};
